<template>
	<div>
		<div v-for="(tjtm,idx) in tjTmlist" :key="idx" style=" border: 1px solid #4dadea;">
			<div style="font-size: 19px; font-weight: bolder; color: orangered;">
				{{tjtm.title}}
			</div>

			<div style="margin-bottom: 100px;">
				<table class="dtable">
					<tr>
						<td rowspan="2">题目</td>
						<td rowspan="2">选项</td>
						<td rowspan="2">所占百分比</td>
						<td rowspan="2">选择人数</td>
						<td colspan="4">2011届2016年毕业</td>
						<td colspan="4">2012届2017年毕业</td>
						<td colspan="4">2013届2018年毕业</td>
						<td colspan="4">2014届2019年毕业</td>
						<td colspan="4">2015届2020年毕业</td>
					</tr>
					<tr>

						<td>护理系</td>
						<td>医学技术系 </td>
						<td>健康服务系</td>
						<td>合计</td>


						<td>护理系</td>
						<td>医学技术系 </td>
						<td>健康服务系</td>
						<td>合计</td>

						<td>护理系</td>
						<td>医学技术系 </td>
						<td>健康服务系</td>
						<td>合计</td>

						<td>护理系</td>
						<td>医学技术系 </td>
						<td>健康服务系</td>
						<td>合计</td>

						<td>护理系</td>
						<td>医学技术系 </td>
						<td>健康服务系</td>
						<td>合计</td>

					</tr>
					<template v-for="(tm,i1) in  tjtm.data">
						<tr v-for="(xx,i) in  tm.tm_content" :key="'xx_'+i+'_'+i1">
							<td v-if="i==0" :rowspan="tm.tm_content.length">{{tm.tm_title}}</td>
							<td>{{xx.title}}</td>
							<td>{{xx.percent}}</td>
							<td>{{xx.choose_count}}</td>
							<template v-for="(year,y) in xx.gyears">

								<td>{{year.major['护理系']}}</td>
								<td>{{year.major['医学技术系']}}</td>
								<td>{{year.major['健康服务系']}}</td>
								<td>{{year.count}}</td>

							</template>
						</tr>
						<tr>
							<td colspan="3">合计</td>
							<td>{{tm.total}}</td>
							<td v-for="(hj,i) in tm.heji" :key="'hj_'+i">
								{{hj}}
							</td>
							
						</tr>
					</template>


				</table>

			</div>
		</div>





	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				plan_id: 0,
				ivst_id: 0,
				planInfo: {},
				tjTmlist: [],
				gkInfo: {},
				laodok: false,
				AllStudents: [],
				StuDic: {},
				txtPie: []
			}
		},
		mounted() {
			this.plan_id = this.$route.query.plan_id
			this.getGK()
		},
		methods: {


			getGK() {
				this.$http.post("api/ivst_plan", {
					plan_id: this.plan_id,
				}).then(res => {
					this.planInfo = res.data
				})
				this.$http.post("api/get_ivst_tj_gk", {
					plan_id: this.plan_id
				}).then(res => {

					let gkInfo = res.data;
					if (gkInfo.finish_ok > 0) {
						gkInfo.type_mini_p = parseInt(gkInfo.type_mini / gkInfo.finish_ok * 10000) / 100
						gkInfo.type_sms_p = parseInt(gkInfo.type_sms / gkInfo.finish_ok * 10000) / 100
						gkInfo.type_mail_p = parseInt(gkInfo.type_mail / gkInfo.finish_ok * 10000) / 100

					} else {
						gkInfo.type_mini_p = 0
						gkInfo.type_sms_p = 0
						gkInfo.type_mail_p = 0
					}
					gkInfo.percent = parseInt(gkInfo.finish_ok / gkInfo.stu_total * 10000) / 100


					this.gkInfo = gkInfo
					console.log('概况')
					console.log(gkInfo)

					this.$http.post("api/njwx_stu_list_years", {}).then(res => {
						let gyear = [],
							gyeardic = {},
							StuDic = {}
						for (let y of res.data) {
							if (!gyeardic[y.gyear]) {
								gyeardic[y.gyear] = [y]
							} else {
								gyeardic[y.gyear].push(y)
							}
							StuDic[y.openid] = y
						}

						//console.log(StuDic)
						this.StuDic = StuDic
						this.AllStudents = gyeardic
						console.log('年度')
						console.log(gyeardic)
						console.log('学生')

						for (let k in StuDic) {
							console.log(StuDic[k])
							break
						}
						this.getTongji()
					})

				})
			},
			getTongji() {

				this.$http.post("api/get_ivst_tj_tm", {
					plan_id: this.plan_id,
				}).then(res => {
					for (let item of res.data) {
						let arr = item.tm_content.sort(function(a, b) {
							return a.choose_count - b.choose_count
						})
						item.tm_content = arr;
					}


					//界别
					let jiebie = [];
					for (let k in this.AllStudents) {
						jiebie.push({
							gyear: k,
							count: 0
						})
					}
					//分组

					let firstTm = res.data[0]
					let fenlei = []
					let first = {
						title: "您现在的情况",
						data: [firstTm]
					}

					fenlei.push(first)

					fenlei.push({
						title: "所有人",
						data: []
					})

					for (let tm of firstTm.tm_content) {
						let t = {
							title: tm.title,
							data: [],

						}
						fenlei.push(t)
					}


					for (let tm of res.data) {
						tm.gyears = JSON.parse(JSON.stringify(jiebie))
						for (let k in tm.students) {
							let year = this.StuDic[k].gyear
							if (year) {
								for (let gyear of tm.gyears) //gyears:JSON.parse(JSON.stringify(jiebie))
								{

									if (gyear.gyear == year) {
										gyear.count++

									}
								}
							}
						}

						let ok = false

						for (let fl of fenlei) {

							if (tm.tm_title.indexOf(fl.title) >= 0) {
								if (tm.tm_title == "您现在的情况") {
									//console.log(tm.tm_title)
								}

								if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {
									for (let xx of tm.tm_content) {
										xx.gyears = JSON.parse(JSON.stringify(jiebie))


										for (let openid of xx.students) {

											let year = this.StuDic[openid].gyear
											if (year) {
												for (let gyear of xx
														.gyears) //gyears:JSON.parse(JSON.stringify(jiebie))
												{

													if (gyear.gyear == year) {
														gyear.count++

													}
												}
											}
										}
									}

								} else if (tm.tm_title.indexOf("平均年收入(税后)") > 0) {
									console.count(tm.tm_title)
									let jie = JSON.parse(JSON.stringify(jiebie))
									for (let k of jie) {
										k.money = []
									}
									for (let a of tm.answers) {
										let year = this.StuDic[a.openid].gyear
										if (year) {

											for (let y of jie) {
												if (y.gyear == year) {
													let salary = a.answers[0];

													if (isNaN(salary)) {
														//console.log(salary)
													} else {
														salary = parseInt(salary)
														if (salary < 100) {
															salary = salary * 1000;
														}
														y.money.push(salary)
													}

													break
												}
											}
										}
									}
									tm.jie = jie
									ok = true
									//图表
									//计算工资段
									for (let j of jie) {
										j.salary = [0, 0, 0, 0, 0, 0]
										for (let s of j.money) {
											let ms = s / 12
											if (ms <= 2000) {
												j.salary[0]++
											} else if (ms > 2000 && ms <= 3500) {
												j.salary[1]++
											} else if (ms > 3500 && ms <= 5000) {
												j.salary[2]++
											} else if (ms > 5000 && ms <= 6500) {
												j.salary[3]++
											} else if (ms > 6500 && ms <= 8000) {
												j.salary[4]++
											} else if (ms > 8000) {
												j.salary[5]++
											}
										}
									}


									this.txtPie = jie


								}

								fl.data.push(tm)
							}
							if (ok) {
								break
							}
						}
					}
					fenlei[0].data = [fenlei[0].data[0]]
					let majorData = {
						2016: {
							1101: "护理系",
							1102: "护理系",
							1103: "护理系",
							1104: "护理系",
							1105: "护理系",
							1106: "护理系",
							1107: "护理系",
							1108: "护理系",
							1109: "护理系",
							1110: "护理系",
							1111: "护理系",
							1112: "医学技术系",
							1113: "医学技术系",
							1114: "医学技术系",
							1115: "健康服务系"
						},
						2017: {
							1201: "护理系",
							1202: "护理系",
							1203: "护理系",
							1204: "护理系",
							1205: "护理系",
							1206: "护理系",
							1207: "护理系",
							1208: "护理系",
							1209: "护理系",
							1210: "护理系",
							1211: "护理系",
							1212: "医学技术系",
							1213: "医学技术系",
							1214: "医学技术系",
							1215: "健康服务系"
						},
						2018: {
							1301: "护理系",
							1302: "护理系",
							1303: "护理系",
							1304: "护理系",
							1305: "护理系",
							1306: "护理系",
							1307: "护理系",
							1308: "护理系",
							1309: "护理系",
							1310: "护理系",
							1311: "护理系",
							1312: "医学技术系",
							1313: "医学技术系",
							1314: "医学技术系",
							1316: "医学技术系",
							1315: "健康服务系"
						},
						2019: {
							1401: "护理系",
							1402: "护理系",
							1403: "护理系",
							1404: "护理系",
							1405: "护理系",
							1406: "护理系",
							1407: "护理系",
							1408: "护理系",
							1409: "护理系",
							1410: "护理系",
							1411: "护理系",
							1412: "医学技术系",
							1413: "医学技术系",
							1414: "医学技术系",
							1415: "健康服务系"
						},
						2020: {
							1501: "护理系",
							1502: "护理系",
							1503: "护理系",
							1504: "护理系",
							1505: "护理系",
							1506: "护理系",
							1507: "护理系",
							1508: "护理系",
							1509: "护理系",
							1510: "护理系",
							1511: "护理系",
							1512: "医学技术系",
							1513: "医学技术系",
							1514: "医学技术系",
							1515: "健康服务系"
						}
					}
					let majorArr = {
						"护理系": 0,
						"医学技术系": 0,
						"健康服务系": 0,
					}
					//计算百分比
					let notexit = {}
					for (let item of fenlei) {
						
						
						for (let tm of item.data) {
							let total = 0;
							for (let xx of tm.tm_content) {
								total += xx.choose_count
							}
							tm.total = total

							for (let xx of tm.tm_content) {
								xx.percent = parseInt(Math.round(xx.choose_count / tm.total * 10000)) / 100
								
								for (let gy of xx.gyears) {
									gy.major = JSON.parse(JSON.stringify(majorArr))
								}
							}
							
							for (let xx of tm.tm_content) {
								for (let gy of xx.gyears) {
									
									for (let stuopenid of xx.students) { //计算专业学生数
									
									
										if (this.StuDic[stuopenid]) {
											
											let stuok = false
											let stucls = this.StuDic[stuopenid]
											if (majorData[stucls.gyear] && majorData[stucls.gyear][stucls
													.class_name
												]&&gy.gyear==stucls.gyear) {
												let majorname = majorData[stucls.gyear][stucls.class_name]
												
												//console.log(majorname)
												
												if (majorname) {
													gy.major[majorname]++
												} else {
													console.log("未找到专业")
												}

											} else {
												//notexit[stuopenid]=1
											
											}

										} else {
											
										}

									}
								}
							}




						}




					}




					
					//计算合计
					for(let tjtm of fenlei){
						for(let tm of tjtm.data){
							let hejiArr=[];
							for(let i=0;i<20;i++){
								hejiArr.push(0)
							}
							
							
							for(let xx of tm.tm_content){
								hejiArr[0]+=xx.gyears[0].major["护理系"]
								hejiArr[1]+=xx.gyears[0].major["医学技术系"]
								hejiArr[2]+=xx.gyears[0].major["健康服务系"]
								hejiArr[3]+=xx.gyears[0].count
								
								hejiArr[4]+=xx.gyears[1].major["护理系"]
								hejiArr[5]+=xx.gyears[1].major["医学技术系"]
								hejiArr[6]+=xx.gyears[1].major["健康服务系"]
								hejiArr[7]+=xx.gyears[1].count
								
								hejiArr[8]+=xx.gyears[2].major["护理系"]
								hejiArr[9]+=xx.gyears[2].major["医学技术系"]
								hejiArr[10]+=xx.gyears[2].major["健康服务系"]
								hejiArr[11]+=xx.gyears[2].count
								
								
								hejiArr[12]+=xx.gyears[3].major["护理系"]
								hejiArr[13]+=xx.gyears[3].major["医学技术系"]
								hejiArr[14]+=xx.gyears[3].major["健康服务系"]
								hejiArr[15]+=xx.gyears[3].count
								
								
								hejiArr[16]+=xx.gyears[4].major["护理系"]
								hejiArr[17]+=xx.gyears[4].major["医学技术系"]
								hejiArr[18]+=xx.gyears[4].major["健康服务系"]
								hejiArr[19]+=xx.gyears[4].count
								
								
							}
							
							tm.heji=hejiArr
						}
					}
					
					this.tjTmlist = fenlei
					
					
					

					console.log(fenlei)

					console.log("出错学生")
					let i=0;
					for(let k in notexit){
						i++
						if(i<10){
							console.log(k)
						}else{
							return
						}
					}

					this.$nextTick(() => {
						this.laodok = true
					})

				})
			},


		}
	}
</script>

<style scoped="scoped">
	.boxitem {
		width: 220px;
		height: 90px;
		display: inline-block;

		background-size: 100% 100%;
		margin-bottom: 20px;
		color: #4dadea;
		font-size: 16px;
		background-color: #ecf5fc;
		border-radius: 8px;
		position: relative;

	}

	.boxitem img {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
		left: 0;
	}


	.boxdesc {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: relative;
		z-index: 1;
	}
</style>
